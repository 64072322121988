import React from "react";
import DrawerLayout from "./DrawerLayout";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  return (
    <>
      <DrawerLayout>
        <Outlet />
      </DrawerLayout>
    </>
  );
};

export default Layout;
