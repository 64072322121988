import * as React from 'react'
import { styled, alpha } from '@mui/material/styles';
import { useUser } from '../../context/userContext';
import { Button, Menu, MenuItem } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar } from "@mui/material";


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 150,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        backgroundColor: '#E3E3E3',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { handleLogout, user } = useUser()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // let initials = user.name.split(' ').map(word => word[0]).join('');


  return (
    <div className='d-flex flex-row'>
      <Avatar sx={{ bgcolor: "var(--main-color)" }} className='mx-2'>{user?.attributes.name.split('_')[0].slice(0,2).toUpperCase()}</Avatar>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: '#8B8B8C' }}
      >
        Hola, {user?.attributes.name.split('_')[0]}
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
        <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ color: 'var(--main-color) !important' }} />
            Logout
        </MenuItem>
      </StyledMenu>
    </div>
  );
}