import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff6600',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ad2931',
    },
    drawer: {
      primary: '#ffffff',
      secondary: '#ACACAC',
    },
    text: {
      primary: '#000000',
      secondary: '#ACACAC',
    },
  },
});