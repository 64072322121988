import * as React from 'react';
import axios from 'axios';
import { Typography, Button, Select, MenuItem } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import RelayerTransactions from '../models/RelayerTransactions/RelayerTransactions';
import { useUser } from "../context/userContext";
import Skeleton from '@mui/material/Skeleton';
import BounceLoader from "react-spinners/BounceLoader";

const tableLimit = 15;

const DashboardRelayer = () => {
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [finalLoading, setFinalLoading] = React.useState(true);
    const { getHeader } = useUser();
    const [relayerInfo, setRelayerInfo] = React.useState();
    const [relayerId, setRelayerId] = React.useState('Relayer_1');
    const [relayerData, setRelayerData] = React.useState(1);
    const [relayerTransactions, setRelayerTransactions] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({});
    const [isContractPaused, setIsContractPaused] = React.useState(false);
    const [isFilterActive, setIsFilterActive] = React.useState(false);

    React.useEffect(() => {
        setPausedState();
    }, [relayerInfo]);

    const setPausedState = () => {
        if (!relayerInfo) return;
        setIsContractPaused(relayerInfo.ContractPaused);
    };

    const getTransactions = async (_limit, _lastKey) => {
        try {
            const headers = await getHeader();
            const transactions = await axios.get(
                `${process.env.REACT_APP_API_BASE}/relayer/transactions?count=${_limit}&lastKey=${_lastKey}`,
                { headers }
            );
            setPaginationData(transactions.data.pagination);
            setRelayerTransactions([...relayerTransactions, ...transactions.data.body]);
            setInitialLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePaginationChange = () => {
        getTransactions(tableLimit, paginationData.nextPageKey);
    };

    const getRelayerData = async () => {
        try {
            const headers = await getHeader();
            const info = await axios.get(`${process.env.REACT_APP_API_BASE}/relayer/information?id=${relayerId}`, { headers });
            const json = JSON.parse(info.data.body);
            setRelayerInfo(json);
            setRelayerData(json['Relayer_1']);
            setFinalLoading(false);
        } catch (error) {
            console.error(error);
            getRelayerData();
        }
    };

    const handleRelayerSwitch = (event) => {
        const selectedRelayerId = event.target.value;
        const selectedRelayer = relayerInfo[selectedRelayerId];
        setRelayerId(selectedRelayerId);
        setRelayerData(selectedRelayer);
    };

    React.useEffect(() => {
        getRelayerData();
        getTransactions(tableLimit, "");
    }, []);

    const handleFilterChange = (isActive) => {
        setIsFilterActive(isActive);
    };

    return (
        <Container>
            <Typography variant='h6' component='h2' color='primary' sx={{ fontSize: '1.75rem', fontWeight: 'bold', marginBottom: '1rem' }}>Dashboard Relayer</Typography>
            {finalLoading
                ? <>
                    <Row className="my-4">
                        <Col xs={3}><Skeleton variant="rectangular" width={200} height={40} /></Col>
                        <Col><Skeleton variant="rectangular" width={400} height={40} /></Col>
                    </Row>
                    <Row className="my-4">
                        <Col xs={3}><Skeleton variant="rectangular" width={300} height={40} /></Col>
                        <Col><Skeleton variant="rectangular" width={500} height={40} /></Col>
                    </Row>
                    <Row className="my-4">
                        <Col><Skeleton variant="rectangular" width={630} height={40} /></Col>
                    </Row>
                </>
                :  
                <>
                    <Select
                        variant="outlined"
                        value={relayerId}
                        onChange={handleRelayerSwitch}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ width: "25%" }}
                    >
                        {Object.entries(relayerInfo).map(([key, value]) => {
                            if (
                                key === "Email" ||
                                key === "Threshold" ||
                                key === "ContractPaused"
                            ) {
                                return null;
                            }
                            return (
                                <MenuItem key={key} value={key}>
                                    <em>{key}</em>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <RelayerTransactions 
                        _transactions={relayerTransactions} 
                        handleFilterChange={handleFilterChange} // Pasamos la función de devolución de llamada como prop
                    />
                </>
            }
            {initialLoading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '30vh' }}>
                    <BounceLoader color='#ff6600' />
                </div>
                : <>
                    {!isFilterActive && // Mostrar el botón solo si no hay un filtro activo
                        paginationData.nextPageKey && (
                            <Row className='my-3'>
                                <Col className='mx-4 d-flex justify-content-end'>
                                    <Button variant='contained' color='primary' onClick={handlePaginationChange}>Cargar más transacciones</Button>
                                </Col>
                            </Row>
                        )
                    }
                </>
            }
        </Container>
    );
};

export default DashboardRelayer;
