import "./App.css";
import Layout from "./layout/Layout";
import Home from "./pages/Home/Home";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import Login from "./pages/Login/Login";
import ProtectedRoutes from "./required/ProtectedRoutes";
import AllCourses from "./pages/AllCourses";
import Course from "./pages/Course";
import DashboardRelayer from './pages/DashboardRelayer';
import NewCertificate from "./pages/NewCertificate/NewCertificate";
import Logs from "./pages/Logs";
import ReviewCertificate from "./pages/ReviewCertificate/ReviewCertificate";
import { Templates } from "./pages/Templates/Templates";


function App() {
  const location = useLocation();
  return (
    <>
      <Routes>
        <Route path="/" element={<Outlet />} />
        <Route element={<ProtectedRoutes />}>
          <Route index element={<Navigate to="/nuevo-certificado" state={{ from: location }} replace />} />
          <Route element={<Layout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/cursos" element={<AllCourses />} />
            <Route path="/cursos/:hash" element={<Course />} />
            <Route path="/dashboard-relayer" element={<DashboardRelayer />} />
            <Route path="/nuevo-certificado" element={<NewCertificate />} />
            <Route path="/review-certificado/:hash" element={<ReviewCertificate />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/templates" element={<Templates />} />

          </Route>
        </Route>
        <Route path="/login" exact element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
