/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DatePickerComponent from "../DatePicker/DatePickerComponent";
import { useUser } from "../../context/userContext";

export default function CourseParticipantsTable({
  _header,
  _rows,
  _eventName,
  _hashEvent,
  filters,
  onFilterChange,
  onSearch,
}) {
  const { getHeader } = useUser();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [responseData, setResponseData] = React.useState(_rows);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Wallet copiada al portapapeles");
  };

  const handleSearch = () => {
    console.log("filters", filters);
    onSearch(filters);
  };

  const handleFilterChange = (name, value) => {
    onFilterChange(name, value);
  };

  React.useEffect(() => {
    setResponseData(_rows);
  }, [_rows]);

  const handleDateRangeChange = (startDate, endDate) => {
    handleFilterChange("selectedDateStart", startDate);
    handleFilterChange("selectedDateEnd", endDate);
  };

  return (
    <>
      <Box display="flex" justifyContent="start" alignItems="center" my={3}>
        <TextField
          value={filters.searchName}
          onChange={(e) => handleFilterChange("searchName", e.target.value)}
          placeholder="Buscar por nombre"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <SearchIcon style={{ color: "gray" }} />,
            style: {
              borderRadius: "20px",
              backgroundColor: "#f5f5f5",
              padding: "8px 12px",
            },
          }}
          sx={{
            width: "200px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
        <Box mx={2} sx={{ width: "400px" }}>
          <DatePickerComponent handleDateRangeChange={handleDateRangeChange} />
        </Box>
        <Select
          value={filters.mintedFilter}
          onChange={(e) => handleFilterChange("mintedFilter", e.target.value)}
          sx={{
            width: "150px",
            borderRadius: "5px",
            borderWidth: "1px",
            marginRight: "10px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& fieldset": {
                borderColor: "#8b0000",
              },
              "&:hover fieldset": {
                borderColor: "#8b0000",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#8b0000",
              },
              "& .MuiSelect-iconOutlined": {
                color: "#8b0000",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  color: "#8b0000",
                },
              },
            },
          }}
        >
          <MenuItem value="Estados">Todos</MenuItem>
          <MenuItem value="minteado">Minteado</MenuItem>
          <MenuItem value="noMinteado">No Minteado</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "3rem" }}
        >
          Buscar
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ minWidth: 650, maxWidth: "100%" }}
      >
        <Table
          sx={{ minWidth: 650, maxWidth: "100%", fontWeight: "600" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {_header.length > 0
                ? _header.map((col, index) => (
                    <TableCell
                      key={index}
                      align={
                        index === 0 || col === "Estado" ? "center" : "left"
                      }
                      sx={{ fontWeight: "700" }}
                    >
                      {col}
                    </TableCell>
                  ))
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {responseData
              ? responseData.map((row) => (
                  <TableRow
                    key={row.idGalicia}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ fontWeight: "500" }}
                    >
                      {row.idGalicia.toString().padStart(4, "0")}
                    </TableCell>
                    <TableCell align="left">{row.participantName}</TableCell>
                    <TableCell align="left">{row.surname}</TableCell>
                    <TableCell align="left">{_eventName}</TableCell>
                    <TableCell align="center">
                      {row.minted ? (
                        <Chip
                          label="Minteado"
                          sx={{
                            width: "105px",
                            backgroundColor: "#CAE9C7",
                            color: "#155A03",
                            fontWeight: "bold",
                          }}
                        />
                      ) : (
                        <Chip
                          sx={{
                            width: "105px",
                            backgroundColor: "#F1B2B2",
                            color: "#750505",
                            fontWeight: "bold",
                          }}
                          label="No minteado"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.minted
                        ? new Date(row.modificationDate).toLocaleString()
                        : null}
                    </TableCell>
                    <TableCell align="left">
                      {row.walletAddress &&
                        row.walletAddress.slice(0, 5) +
                          "..." +
                          row.walletAddress.slice(-5)}
                      {row.walletAddress && (
                        <FileCopyIcon
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "gray",
                            width: "15px",
                          }}
                          onClick={() => copyToClipboard(row.walletAddress)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : _rows.map((row) => (
                  <TableRow
                    key={row.idGalicia}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ fontWeight: "500" }}
                    >
                      {row.idGalicia.toString().padStart(4, "0")}
                    </TableCell>
                    <TableCell align="left">{row.participantName}</TableCell>
                    <TableCell align="left">{row.surname}</TableCell>
                    <TableCell align="left">{_eventName}</TableCell>
                    <TableCell align="center">
                      {row.minted ? (
                        <Chip
                          label="Minteado"
                          sx={{
                            width: "105px",
                            backgroundColor: "#CAE9C7",
                            color: "#155A03",
                            fontWeight: "bold",
                          }}
                        />
                      ) : (
                        <Chip
                          sx={{
                            width: "105px",
                            backgroundColor: "#F1B2B2",
                            color: "#750505",
                            fontWeight: "bold",
                          }}
                          label="No minteado"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.minted
                        ? new Date(row.modificationDate).toLocaleString()
                        : null}
                    </TableCell>
                    <TableCell align="left">
                      {row.walletAddress &&
                        row.walletAddress.slice(0, 5) +
                          "..." +
                          row.walletAddress.slice(-5)}
                      {row.walletAddress && (
                        <FileCopyIcon
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "gray",
                            width: "15px",
                          }}
                          onClick={() => copyToClipboard(row.walletAddress)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
