import * as React from "react";
import ModalCard from "../ModalCard";
import { Typography, LinearProgress } from "@mui/material";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";

const WaitingModal = ({
  _open,
  _handleClose,
  _title = "Emitiendo certificados...",
  _subtitle = "Por favor no cierre esta ventana.",
}) => {
  return (
    <ModalCard
      className="modal-properties waiting-modal-properties"
      open={_open}
      handleClose={_handleClose}
    >
      <div className="modal-container">
        <div className="d-flex align-items-center">
          <GppGoodOutlinedIcon sx={{ fontWeight: "600", fontSize: '2.5rem' }} />
          &nbsp;&nbsp;
          <Typography variant="h6" sx={{ fontWeight: "600", fontSize: '1.5rem' }}>
            {_title}
          </Typography>
        </div>
        <Typography className={"modal-text my-2"}>{_subtitle}</Typography>
        <LinearProgress sx={{ width: "80%", height: "7px" }} />
      </div>
    </ModalCard>
  );
};

export default WaitingModal;
