import * as React from 'react';
import { Auth } from 'aws-amplify';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = () => {
    const location = useLocation();
    const [isAuth, setIsAuth] = React.useState(null);
    const [initialLoading, setInitialLoading] = React.useState(true);

    const checkAuth = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsAuth(true);
        } catch (error) {
            setIsAuth(false)
        } finally {
            setInitialLoading(false)
        }
    };

    React.useEffect(() => {
        checkAuth();
    }, [])

    if (initialLoading) return <></>

    if (!isAuth) return <Navigate to="/login" state={{ from: location }} replace />

    return <Outlet />
}

export default ProtectedRoutes