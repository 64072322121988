import * as React from "react";
import "./DrawerLayout.css";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../assets/logoCircular.svg";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu'
import { useUser } from "../context/userContext";
// icons
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NuevoIcon from "../assets/icons/Nuevo.svg";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  padding: "0rem 5rem",
  boxShadow: 'none',
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerLayout = ({ children }) => {
  const theme = useTheme();
  const { linksList } = useUser();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="absolute" open={open} >
        <Toolbar sx={{ justifyContent: "end", padding: "0rem 5rem" }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              width: '100%',
              ...(open && { display: "none" }),
            }}
          >
            <ChevronRightIcon  style={{width: '100%'}}/>
          </IconButton>
          <div className='d-flex align-items-baseline justify-content-center'>
            
            <NavbarMenu />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        {/* <Link to={"/home"}> */}
          <img
            src={Logo}
            alt="logo"
            width="50px"
            style={{ margin: "0px 0px 20px 20px" }}
          />
        {/* </Link> */}

        <Divider sx={{ backgroundColor: "#E3E3E3" }} />

        <List style={{ color: "#6A6A6A" }}>
        {linksList?.length > 0
            ? linksList.map((link) => (
              <ListItem
                key={link.idPage}
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to={link.pageRoute}>
                  <ListItemButton sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: "black",
                      }}
                    >
                      {link.pageRoute === '/nuevo-certificado' && <img src={NuevoIcon} alt='icono' style={{ filter: 'invert(50%)' }} />}
                      {link.pageRoute === '/cursos' && <GppGoodOutlinedIcon sx={{ color: '#6A6A6A' }} />}
                      {link.pageRoute === '/dashboard-relayer' && <InsertChartOutlinedIcon sx={{ color: '#6A6A6A' }} />}
                      {link.pageRoute === '/logs' && <FeedOutlinedIcon sx={{ color: '#6A6A6A' }} />}
                      {link.pageRoute === '/templates' && <FeedOutlinedIcon sx={{ color: '#6A6A6A' }} />}
                    </ListItemIcon>
                    <ListItemText
                      primary={link.pageName}
                      sx={{ opacity: open ? 1 : 0, fontWeight: "800" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))
            : null}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default DrawerLayout;
