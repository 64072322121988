/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import * as React from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useUser } from '../../context/userContext';
import CarouselSlider from './CarouselSlider';

// componente
const NewSvgComponent = ({ _newSvg }) => {
    const svgRef = React.useRef(null);
    const [viewBox, setViewBox] = React.useState(null);

    React.useEffect(() => {
        if (svgRef.current) {
            const bbox = svgRef.current.getBBox();
            setViewBox(`${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
        }
    }, [_newSvg]);

    return (
        <div className="svg-component-container">
            <svg className="svg-component" id="svg-container" viewBox={viewBox} ref={svgRef} dangerouslySetInnerHTML={{ __html: _newSvg }} />
        </div>
    );
};


const SlideShow = ({ _participants, _hashEvent }) => {

    const { getHeader } = useUser();
    // svg
    const [renderSvg, setRenderSvg] = React.useState(null);
    // carrusel
    const [index, setIndex] = React.useState(0);

    const parseSvg = async (_index) => {
        try {
            const headers = await getHeader()
            const svgParseApi = await axios.get(`${process.env.REACT_APP_API_BASE}/events/participant/preview?name=${_participants[_index]?.participantName}&surname=${_participants[_index]?.surname}&hashEvent=${_hashEvent}`, { headers })
            
            const finalSvg = svgParseApi.data.body.svg

            setRenderSvg(finalSvg);

        } catch (error) {
            console.log("error parsing svg: ", error);
        }
    };

    //parsear el svg
    React.useEffect(() => {
        parseSvg(index)
    }, [index]);

    return (
        <>
            <Container>
                {(_participants && _participants.length > 0)
                    ? <><CarouselProvider
                        naturalSlideWidth={70}
                        naturalSlideHeight={40}
                        totalSlides={_participants.length}
                        touchEnabled={false}
                        dragEnabled={false}
                        currentSlide={index}
                    >
                        <Slider>
                            {
                                _participants.map((participant) => {
                                    return (
                                        <Slide key={participant.idGalicia} index={participant.idGalicia}>
                                            <div style={{ maxWidth: '100%', height: '100%' }}>
                                                <NewSvgComponent className="svg-component" id='svg-container' _newSvg={renderSvg} style={{ width: '100%', height: '100%' }} />
                                            </div>
                                        </Slide>
                                    );
                                })
                            }
                        </Slider>
                        <CarouselSlider setIndex={setIndex} index={index} participantLength={_participants.length} />
                    </CarouselProvider>
                    
                    <div className="certificates-amount">
                        <label>Certificado {index + 1} de {_participants.length}</label>
                    </div>
                    </>
                    : <div style={{ maxWidth: '100%', height: '100%' }}>
                    <NewSvgComponent className="svg-component" id='svg-container' _newSvg={renderSvg}  style={{ width: '100%', height: '100%' }}/>
                  </div>
                }
            </Container>
         
        </>
    )
};

export default SlideShow