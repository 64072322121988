import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import './DropdownSearch.css';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  marginTop: theme.spacing(1),
  left: 0,
  right: 0,
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}));

const StyledInput = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: '20px',
  padding: '8px 12px', // Aumentamos el padding
  display: 'flex',
  alignItems: 'center',
}));

const DropdownSearch = ({ list, onOptionClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const filteredOptions = list.filter((item) =>
    item.toLowerCase().includes(search.toLowerCase())
  );

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleOptionClick = (option) => {
    onOptionClick(option);
    setIsOpen(false);
    setSearch('');
  };

  return (
    <div className="dropdown-search">
      <StyledInput>
        <input
          type="text"
          placeholder="Buscar por nombre"
          value={search}
          onChange={handleSearchChange}
          onFocus={toggleDropdown}
          style={{ border: 'none', backgroundColor: 'transparent', outline: 'none', flexGrow: 1 }}
        />
        <SearchIcon color="action" /> {/* Cambiamos el color del ícono a gris */}
      </StyledInput>
      {isOpen && (
        <StyledPaper>
          <div className="dropdown">
            <ul className="list-unstyled">
              {filteredOptions.slice(0, 4).map((option, index) => (
                <li
                  key={index}
                  className="dropdown-option ml-2"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </StyledPaper>
      )}
    </div>
  );
};

export default DropdownSearch;