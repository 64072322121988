import * as React from "react"

const BotonLista = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M24 0v16.96c-.04-.228-.076-.456-.116-.684-.613-3.428-4.043-5.804-7.48-5.188a6.515 6.515 0 0 0-5.354 7.236c.317 2.628 2.3 4.86 4.88 5.476.361.088.734.136 1.098.2H0v-3.24a.556.556 0 0 1 .036.068c.208.752.761 1.168 1.543 1.168 2.908.003 5.82.003 8.734 0 .365 0 .461-.16.285-.472a7.84 7.84 0 0 1-.874-2.18c-.08-.34-.1-.352-.445-.352H3.59c-.389 0-.585-.192-.585-.572 0-4.285.001-8.57.004-12.856a.7.7 0 0 1 .128-.384c.112-.172.313-.176.505-.176H14.32c.525 0 .677.152.677.68v3.8c0 .272.104.368.34.34a212.81 212.81 0 0 1 2.304-.28c.309-.032.357-.076.357-.38a977.73 977.73 0 0 0 0-5.576c0-.964-.633-1.584-1.599-1.584a584.964 584.964 0 0 0-3.746.004c-.204 0-.325-.064-.437-.24m-3.241 7.48c.982 0 1.96.004 2.94 0 .1.003.2-.006.297-.028.365-.1.581-.448.53-.832a.72.72 0 0 0-.714-.632H5.962a.745.745 0 0 0-.705.86.736.736 0 0 0 .737.632c.994.004 1.987 0 2.981 0Zm.012 1.996h-2.94c-.362 0-.642.184-.754.492-.184.5.176 1.012.737 1.012 1.616.008 3.232.007 4.848-.004.104 0 .236-.08.317-.16.324-.332.637-.676.953-1.02.044-.061.082-.125.116-.192a1.638 1.638 0 0 0-.232-.124c-.056-.02-.12-.008-.18-.008l-2.865.004Zm-1.222 3.508c-.573 0-1.15-.004-1.723 0-.465 0-.79.312-.793.74-.004.428.324.752.785.752h3.298c.2 0 .288-.088.324-.276.052-.252.116-.504.184-.752.097-.344.008-.468-.356-.468-.572 0-1.145.001-1.72.004Z"
        fill="#fff"
      />
      <path
        d="M12.653 2.008c1.25-.008 2.499-.01 3.746-.004.966 0 1.599.62 1.599 1.584a977.73 977.73 0 0 1 0 5.576c0 .304-.048.348-.357.38-.769.088-1.538.184-2.303.28-.237.028-.341-.068-.341-.34v-3.8c0-.528-.152-.68-.677-.68H3.642c-.192 0-.393.004-.505.176a.7.7 0 0 0-.128.384c-.003 4.285-.004 8.57-.004 12.856 0 .38.196.572.585.572h5.69c.344 0 .364.012.444.352a7.84 7.84 0 0 0 .874 2.18c.176.312.08.472-.285.472-2.911.003-5.823.003-8.734 0-.782 0-1.335-.416-1.543-1.168A.563.563 0 0 0 0 20.76V3.24a.216.216 0 0 0 .028-.052C.284 2.372.785 2 1.638 2c1.235 0 2.465-.004 3.699.004"
        fill="#8B8B8C"
      />
      <path
        d="M17.029 24c-.365-.064-.738-.112-1.098-.2-2.58-.616-4.564-2.848-4.88-5.476-.417-3.496 1.887-6.612 5.353-7.236 3.465-.624 6.867 1.76 7.48 5.188.04.228.076.456.116.684v1.12a.803.803 0 0 0-.036.132c-.284 2.688-2.272 4.956-4.896 5.588-.357.088-.717.136-1.078.2h-.961Zm-.525-4.836v.68c.004.232-.004.468.004.7a.983.983 0 0 0 .933.944c.601.024 1.054-.408 1.058-1.028.004-.88 0-1.756.004-2.636 0-.248.076-.324.32-.332.245-.008.43 0 .642 0 .212 0 .376-.08.476-.268.1-.188.065-.388-.072-.56-.657-.824-1.314-1.648-1.975-2.464-.216-.268-.565-.276-.777-.012-.674.828-1.335 1.664-2 2.496a.467.467 0 0 0-.064.524c.088.188.245.28.449.284h.601c.336 0 .405.068.405.412 0 .42 0 .84-.004 1.26Z"
        fill="#8B8B8C"
      />
      <path
        d="M5.337 2.004C4.103 1.996 2.873 2 1.639 2 .785 2 .284 2.372.028 3.188A.216.216 0 0 1 0 3.24V0M17.99 24c.36-.064.721-.112 1.078-.2 2.624-.632 4.612-2.9 4.896-5.588A.793.793 0 0 1 24 18.08V24h-6.01Z"
        fill="#fff"
      />
      <path
        d="M8.975 9.248c-.994 0-1.988.004-2.981 0a.738.738 0 0 1-.737-.632.743.743 0 0 1 .705-.86h6.066a.722.722 0 0 1 .713.632c.052.384-.164.732-.529.832a1.204 1.204 0 0 1-.296.028c-.982.004-1.96 0-2.941 0ZM8.987 11.244h2.865c.06 0 .124-.012.18.008.08.035.158.076.232.124-.034.067-.072.13-.116.192-.316.344-.629.688-.954 1.02-.08.08-.212.16-.316.16-1.616.008-3.232.01-4.848.004-.561 0-.922-.512-.737-1.012.112-.308.392-.492.753-.492l2.94-.004ZM7.765 14.752a92.911 92.911 0 0 1 1.72 0c.364 0 .452.124.356.468-.068.248-.133.5-.185.752-.036.188-.124.276-.324.276H6.034c-.46 0-.789-.32-.785-.752.004-.432.329-.74.793-.74.573-.008 1.15-.004 1.723-.004Z"
        fill="#8B8B8C"
      />
      <path
        d="M16.508 19.164v-1.26c0-.344-.068-.412-.405-.412h-.6a.473.473 0 0 1-.45-.284.468.468 0 0 1 .065-.524c.665-.832 1.326-1.668 1.999-2.496.212-.264.56-.256.777.012.661.816 1.318 1.64 1.976 2.464.136.172.172.36.072.56-.1.2-.265.272-.477.268a17.127 17.127 0 0 0-.641 0c-.213.004-.32.084-.32.332-.005.88 0 1.756-.005 2.636-.004.62-.457 1.052-1.058 1.028a.986.986 0 0 1-.933-.944c-.008-.232 0-.468-.004-.7-.004-.232 0-.452.004-.68Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default BotonLista