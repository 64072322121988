import * as React from "react";
import { Row } from "react-bootstrap";
import {
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import axios from "axios";
import { useUser } from "../../context/userContext";
import AddIcon from "@mui/icons-material/Add";
import TransactionsTable from "../../components/Tables/TransactionsTable";
import fileDownload from "js-file-download";
import SearchIcon from "@mui/icons-material/Search";
import DatePickerComponent from "../../components/DatePicker/DatePickerComponent";

const RelayerTransactions = ({ _transactions, handleFilterChange  }) => {
  const [isLoading, setIsloading] = React.useState(false);
  const { getHeader } = useUser();
  const [isSearching, setIsSearching] = React.useState(false);
  const [mintedFilter, setMintedFilter] = React.useState("Estados");
  const [searchName, setSearchName] = React.useState("");
  const [selectedDateStart, setSelectedDateStart] = React.useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState("");
  const [filteredTransactions, setFilteredTransactions] =
    React.useState(_transactions);
  const [isFiltered, setIsFiltered] = React.useState(false);


  const handleSearch = async () => {
    if (
      mintedFilter === "Estados" &&
      searchName === "" &&
      selectedDateStart === null &&
      selectedDateEnd === null
    ) {
      resetFilters();
      return;
    }
    setIsFiltered(true);
    setIsSearching(true); 
    handleFilterChange(true);
    try {
      const response = await filterData(99999, "");
      setFilteredTransactions(response.body);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false); 
    }
  };
  
  const filterData = async (_limit, _lastKey) => {
    const beginDateTimestamp = selectedDateStart
      ? new Date(selectedDateStart).getTime()
      : null;
      
    const endDateTimestamp = selectedDateEnd
      ? new Date(selectedDateEnd).getTime()
      : null;

    const queryParams = {
      minted:
        mintedFilter === "minteado"
          ? true
          : mintedFilter === "noMinteado"
          ? false
          : undefined,
      id: searchName !== "" ? encodeURIComponent(searchName) : undefined,
      beginDate: beginDateTimestamp !== null ? beginDateTimestamp : undefined,
      endDate: endDateTimestamp !== null ? endDateTimestamp : undefined,
    };

    const queryString = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    const url = `${process.env.REACT_APP_API_BASE}/relayer/transactions?count=${_limit}&lastKey=${_lastKey}&${queryString}`;
    console.log("URL", url);
    
    try {
      const headers = await getHeader();
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateStart(startDate);
    setSelectedDateEnd(endDate);
  };

  const getRelayerTransactions = async (_id, _headers) => {
    setIsloading(true);
    const headers = await getHeader();
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_BASE}/relayer/all`,
        { headers }
      );

      const pre = JSON.parse(data.request.response);
      console.log(pre);
      const transactions = JSON.parse(pre.body);
      console.log("tra", transactions.message);
      const csvHeader =
        "idGalicia;eventName;mintDate;minted;attributesTransaction;hashTransaction\n";
      const csvData = csvHeader + transactions.message;
      fileDownload(csvData, "relayers.csv");
      setIsloading(false);
    } catch (error) {
      setIsloading(false);

      console.error(error);
    }
  };

  const resetFilters = () => {
    setMintedFilter("Estados");
    setSearchName("");
    setSelectedDateStart(null);
    setSelectedDateEnd(null);
    setFilteredTransactions(_transactions);
    setIsFiltered(false);
    handleFilterChange(false);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={{ fontSize: "1.25rem" }}
          >
            Listado de transacciones
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={isLoading ? null : <AddIcon />}
            onClick={getRelayerTransactions}
          >
            {isLoading ? "Cargando" : "Exportar"}
          </Button>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="start" alignItems="center" my={3}>
        <TextField
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder="Buscar por nombre"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <SearchIcon style={{ color: "gray" }} />,
            style: {
              borderRadius: "20px",
              backgroundColor: "#f5f5f5",
              padding: "8px 12px",
            },
          }}
          sx={{
            width: "200px",

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
        <Box mx={2} sx={{ width: "400px" }}>
          <DatePickerComponent handleDateRangeChange={handleDateRangeChange} />
        </Box>
        <Select
          value={mintedFilter}
          onChange={(e) => setMintedFilter(e.target.value)}
          sx={{
            width: "150px",
            borderRadius: "5px",
            borderWidth: "1px",

            marginRight: "10px",

            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& fieldset": {
                borderColor: "#8b0000",
              },
              "&:hover fieldset": {
                borderColor: "#8b0000",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#8b0000",
              },
              "& .MuiSelect-iconOutlined": {
                color: "#8b0000",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  color: "#8b0000",
                },
              },
            },
          }}
        >
          <MenuItem value="Estados">Todos</MenuItem>
          <MenuItem value="minteado">Minteado</MenuItem>
          <MenuItem value="noMinteado">No Minteado</MenuItem>
        </Select>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{ marginLeft: "3rem" }}
          disabled={isSearching}
        >
          {isSearching ? "Cargando" : "Buscar"}
        </Button>
      </Box>

      <TransactionsTable
        header={[
          "ID Galicia",
          "Fecha de Creación",
          "Evento",
          "Minteado",
          "Costo",
          "Hash de la transacción",
        ]}
        rows={isFiltered ? filteredTransactions : _transactions}
      />
    </>
  );
};

export default RelayerTransactions;
