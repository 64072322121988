import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Typography, IconButton } from "@mui/material";
import { BootstrapInput } from "../../Inputs/BootstrapInput";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const TabComponent = ({ setProperties, properties, eventCreated }) => {
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const handleKeyChange = (event) => {
        setKey(event.target.value);
    };

    const handleValueChange = (event) => {
        setValue(event.target.value);
    };

    const removeProperty = (i) => {
        let newFormValues = [...properties];
        var index = newFormValues.map(values => {
            return values.id;
        }).indexOf(i);
        newFormValues.splice(index, 1);
        setProperties(newFormValues);
    };

    const handlePropChange = (i, e) => {
        let newFormValues = [...properties];
        newFormValues[i][e.target.name] = e.target.value;
        setProperties(newFormValues);
    }

    const handleAddProperty = () => {
        setProperties([...properties, { key: key, value: value, id: Date.now() }]);
        setKey("");
        setValue("");
    };

    return (
        <>
            {/* Propiedades ya agregadas */}
            {properties.length > 0
                ? properties.map((element, index) => (
                    <div className="form-inline mb-3" key={element.id}>

                        <div className="d-inline mx-2">
                            <label className="me-2"><Typography variant="button" component="span">Descripción:</Typography></label>
                            <BootstrapInput type="text" disabled={eventCreated} name="trait_type" onChange={e => handlePropChange(index, e)} defaultValue={element.key} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                        </div>
                        <div className="d-inline mx-2">
                            <label className="mx-2"><Typography variant="button" component="span">Valor:</Typography></label>
                            <BootstrapInput type="text" name="value" disabled={eventCreated} onChange={e => handlePropChange(index, e)} defaultValue={element.value} sx={{ '& .MuiInputBase-input': { fontSize: '14px' } }} />
                        </div>
                        <IconButton type="button" disabled={eventCreated} onClick={() => removeProperty(element.id)}><RemoveCircleOutlineIcon /></IconButton>
                    </div>
                ))
                : null}
            {/* Agregar nueva propiedad */}
            {eventCreated
                ? <span className="my-3"></span>
                : <>
                    <Row className="my-1">
                        <Col className='px-0'>
                            <BootstrapInput
                                fullWidth
                                type="text"
                                value={key}
                                onChange={handleKeyChange}
                                placeholder="DESCRIPCIÓN"
                            />
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col className='px-0'>
                            <BootstrapInput
                                fullWidth
                                type="text"
                                value={value}
                                onChange={handleValueChange}
                                placeholder="VALOR"
                            />
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col md={5} className='px-0'>
                            <Button
                                variant="contained"
                                onClick={handleAddProperty}
                                disabled={!key || !value}
                                className='mt-3 mb-4 agregar-btn w-75'
                            >
                                +  Agregar
                            </Button>
                        </Col>
                    </Row>
                </>}
        </>
    )
}

export default TabComponent;