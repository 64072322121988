import React from "react";
import Card from "react-bootstrap/Card";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GlbDisplay from "../../components/GlbDisplay/GlbDisplay";
import ReactPlayer from "react-player";

const ImageBlock = ({ block, updateBack, eye, opacity, divColor }) => {
  const handleClick = () => {
    updateBack(block.idTemplate, block);
  };

  const titleFunction = (title) => {
    if (title.length > 10) {
      return `${title.slice(0, 10)}...`;
    } else {
      return title;
    }
  };

  // console.log('Alejandro Leupold'.slice(0, 16));
  // console.log(titleFunction('AlejandroLeupoldeSouza'));

  const renderContent = () => {
    if (!block.uriTemplateS3) {
      return <div>No se pudo cargar el archivo</div>;
    }

    const fileExtension = block.uriTemplateS3.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
    const videoExtensions = ["mp4"];
    const glbExtensions = ["glb"];

    if (imageExtensions.includes(fileExtension)) {
      return (
        <Card.Img
          variant="bottom"
          src={block.uriTemplateS3}
          style={imageStyle}
        />
      );
    }
    if (videoExtensions.includes(fileExtension)) {
      return (
        <ReactPlayer
          url={block.uriTemplateS3}
          controls
          style={{ ...imageStyle, maxWidth: "11.1rem" }}
        />
      );
    }
    if (glbExtensions.includes(fileExtension)) {
      {
        /* <GlbDisplay _file={block.uriTemplateS3}   style={{...imageStyle, maxWidth:'11.1rem', height:'5rem'}}/> */
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "6.5rem",
          }}
        >
          Archivo GLB
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "6.5rem",
            textAlign: "center",
          }}
        >
          Formato de archivo <br /> no compatible
        </div>
      );
    }
  };

  const imageStyle = {
    opacity,
    transition: "0.5s",
    
    maxHeight: "6.5rem",
    width: "11.1rem",
  };

  return (
    <Card
      style={{
        width: "11.2rem",

        margin: "1rem",
        flex: "0 0 auto",
        background: divColor,
        transition: "0.5s",
      }}
      key={block.idTemplate}
    >
      <Card.Body
        style={{
          display: "flex",
          flexWrap: "wrap",
          height: "2rem",
          paddingTop: "0.5rem",
        }}
      >
        <Card.Title
          style={{
            fontSize: "0.8rem",
            marginTop: "0.3rem",
            marginRight: "1rem",
          }}
        >
          {titleFunction(block.templateName)}
        </Card.Title>
        <div
          variant="contained"
          style={{
            marginLeft: "auto",
          }}
          onClick={handleClick}
        >
          {" "}
          {eye === "visible" ? (
            <svg width="16" height="16" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.43766 7.43879C7.3859 7.43879 8.15461 6.67008 8.15461 5.72184C8.15461 4.77359 7.3859 4.00488 6.43766 4.00488C5.48941 4.00488 4.7207 4.77359 4.7207 5.72184C4.7207 6.67008 5.48941 7.43879 6.43766 7.43879Z" fill="#13BD24" />
                <path d="M12.8512 5.57568C12.3464 4.26978 11.4699 3.14045 10.3302 2.32724C9.1905 1.51404 7.83748 1.05261 6.43839 1C5.03929 1.05261 3.68628 1.51404 2.54657 2.32724C1.40687 3.14045 0.530425 4.26978 0.0255717 5.57568C-0.00852389 5.66998 -0.00852389 5.77325 0.0255717 5.86756C0.530425 7.17345 1.40687 8.30279 2.54657 9.116C3.68628 9.9292 5.03929 10.3906 6.43839 10.4432C7.83748 10.3906 9.1905 9.9292 10.3302 9.116C11.4699 8.30279 12.3464 7.17345 12.8512 5.86756C12.8853 5.77325 12.8853 5.66998 12.8512 5.57568ZM6.43839 8.51167C5.88657 8.51167 5.34714 8.34803 4.88832 8.04146C4.4295 7.73488 4.07189 7.29914 3.86072 6.78932C3.64955 6.27951 3.5943 5.71852 3.70195 5.17731C3.80961 4.63609 4.07533 4.13895 4.46553 3.74876C4.85572 3.35856 5.35286 3.09284 5.89408 2.98518C6.43529 2.87753 6.99628 2.93278 7.50609 3.14395C8.01591 3.35512 8.45165 3.71273 8.75823 4.17155C9.0648 4.63037 9.22844 5.1698 9.22844 5.72162C9.2273 6.46124 8.93299 7.17024 8.41 7.69323C7.88701 8.21622 7.17801 8.51053 6.43839 8.51167Z" fill="#13BD24" />
            </svg>
            // <VisibilityIcon style={{ color: "green", width: "16rem" }} />
          ) : (
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M7.88699 6L10.0003 8.10667V8C10.0003 7.46957 9.78961 6.96086 9.41454 6.58579C9.03947 6.21071 8.53076 6 8.00033 6H7.88699ZM5.02033 6.53333L6.05366 7.56667C6.02033 7.70667 6.00033 7.84667 6.00033 8C6.00033 8.53043 6.21104 9.03914 6.58611 9.41421C6.96118 9.78929 7.46989 10 8.00033 10C8.14699 10 8.29366 9.98 8.43366 9.94667L9.46699 10.98C9.02033 11.2 8.52699 11.3333 8.00033 11.3333C7.11627 11.3333 6.26842 10.9821 5.6433 10.357C5.01818 9.7319 4.66699 8.88406 4.66699 8C4.66699 7.47333 4.80033 6.98 5.02033 6.53333ZM1.33366 2.84667L2.85366 4.36667L3.15366 4.66667C2.05366 5.53333 1.18699 6.66667 0.666992 8C1.82033 10.9267 4.66699 13 8.00033 13C9.03366 13 10.0203 12.8 10.9203 12.44L11.207 12.72L13.1537 14.6667L14.0003 13.82L2.18033 2M8.00033 4.66667C8.88438 4.66667 9.73223 5.01786 10.3573 5.64298C10.9825 6.2681 11.3337 7.11595 11.3337 8C11.3337 8.42667 11.247 8.84 11.0937 9.21333L13.047 11.1667C14.047 10.3333 14.847 9.24 15.3337 8C14.1803 5.07333 11.3337 3 8.00033 3C7.06699 3 6.17366 3.16667 5.33366 3.46667L6.78033 4.9C7.16033 4.75333 7.56699 4.66667 8.00033 4.66667Z" fill="#232323" />
             </svg>
            
          )}
        </div>
      </Card.Body>
      {renderContent()}
    </Card>
  );
};

export default ImageBlock;
