import React, { useState, useEffect } from "react";
import ImageBlocks from "./ImageBlocks";
import { useUser } from "../../context/userContext";
import axios from "axios";


export const Templates = () => {
  const [blocks, setBlocks] = useState([]); //[course1, course2, course3, course4, course5, course6, course7, course8]
  const { getHeader } = useUser();

  const getAllData = async () => {
    try {
      const headers = await getHeader();
      const response = await axios.get(`${process.env.REACT_APP_API_BASE}/templates`, { headers });
      const templates = response.data.body;
      return templates
    } catch (error) {
      console.log(error);
    }
  };

  const toggleVisibility = (id) => {
    setBlocks(prevBlocks =>
      prevBlocks.map(block =>
        block.idTemplate === id ? { ...block, visibility: !block.visibility } : block
      )
    );

  };

  const updateBack = async (id, block) => {
    toggleVisibility(id)
    try {

      const headers = await getHeader();
      const constante = await axios.patch(`${process.env.REACT_APP_API_BASE}/template`, {
        visibility: !block.visibility,
        modificationDate: block.modificationDate, idTemplate: block.idTemplate,
      }, { headers });
    } catch (error) {
      console.log(error);
    }
    // {
    //   "idTemplate":"55118659147466820",
    //   "modificationDate":"1682087986678",
    //   "visibility":true
    // }

  }


  // useEffect(() => {
  //   getAllData();
  // }, [])

  const setData = () => { getAllData().then((data) => setBlocks([...data])) };

  useEffect(() => {
    setData();
  }, [])


  return (

    <>
      <h1 style={{ color: '#FF6600', fontSize: "1.75rem", fontWeight: 'bold' }}>Templates</h1>
      <div className="block-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>


        {blocks.map((block, index) => {

          return block.visibility ? (
            <ImageBlocks block={block} index={index} updateBack={updateBack} key={block.idTemplate} eye={'visible'} opacity={'1'} divColor={'white'} />
          ) : (
            <ImageBlocks block={block} index={index} updateBack={updateBack} key={block.idTemplate} eye={'notvisible'} opacity={'0.3'} divColor={"lightGrey"} />
          )

        }

        )}
      </div>
    </>
  );
};