/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import axios from 'axios'
import { Container } from 'react-bootstrap';
import { Typography } from '@mui/material';
import NuevoCertificado from '../../components/NuevoCertificado/NuevoCertificado'
// useUser
import { useUser } from "../../context/userContext";

const NewCertificate = () => {
  const { getHeader } = useUser();
  const [types, setTypes] = React.useState([]);
  const [templatesList, setTemplatesList] = React.useState([]);

  const getAllData = async () => {
    try {
      const headers = await getHeader();
      const [templates, types] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_BASE}/templates`, { headers }),
        axios.get(`${process.env.REACT_APP_API_BASE}/events/types`, { headers }),
      ])
      console.log('templates', templates.data.body)
      setTypes(types.data.body);
      setTemplatesList(templates.data.body);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAllData();
  }, [])

  return (
    <Container>
      <Typography variant='h6' component='h2' color='primary' sx={{ fontSize: '1.75rem', fontWeight: 'bold', margin: '1rem 0rem' }}>Nuevo Certificado</Typography>
      <NuevoCertificado _templates={templatesList} _types={types} />
    </Container>
  )
}

export default NewCertificate