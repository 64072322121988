import React, { useEffect, useContext } from "react";
import "./Login.css";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Typography, Button } from "@mui/material";
import Background from "../../assets/FOTO1.png";
import LogoGalicia from "../../assets/LogoGalicia.png";
import Certification from "../../assets/Certification.png";

const Login = () => {
  const { handleLogin, user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/nuevo-certificado");
    }
  }, [user]);

  return (
    <div className="login-background">
      <Container className="container-login">
        <Row className="white-background">
          <Col md={4} className="login-column">
            <img
              src={LogoGalicia}
              alt="logo Galicia"
              className="login-galicia-logo"
            />
            <div>
              <Typography
                color="primary"
                sx={{ fontSize: "40px", fontWeight: "bold" }}
              >
                ¡Bienvenido!
              </Typography>
              <Typography color="text" sx={{ fontSize: "20px" }}>
                Podrás emitir y gestionar certificados en la
              </Typography>
              <Typography color="primary" sx={{ fontSize: "20px", fontWeight: "bold" }}>
                Blockchain
              </Typography>

            </div>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleLogin}
              className="login-btn"
            >
              Login
            </Button>
          </Col>
          <Col md={8} className='col-img'>
            <img
              src={Certification}
              alt="logo Galicia"
              className="certification-badge"
            />

            <img
              src={Background}
              alt="background"
              className="img-login-background"
            />
          </Col>
        </Row>
        <Row className=" footer-login">
          <Col>
            <Typography
              color="text.secondary"
              sx={{ fontSize: "12px", fontWeight: "600" }}
            >
              Copyright 2023 | Todos los derechos reservados a BANCO GALICIA
            </Typography>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
