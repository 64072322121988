import * as React from "react";
import "./ModalCard.css";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";

const StyledCard = styled(Card)`
  color: var(--main-color);
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  border-radius: 24px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
`;

const ModalCard = ({ open, handleClose, children, className }) => {
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledCard className={className}>
        {children}
      </StyledCard>
    </Modal>
  );
};

ModalCard.defaultProps = {
  showHeader: false,
};

export default ModalCard;
