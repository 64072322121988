import React, { useContext, useEffect, useState } from "react";

import {
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

// import styled from "styled-components";
import styled from '@emotion/styled'


import Arrow from "./arrow.svg";

const CarouselSlider = ({ setIndex, index, participantLength }) => {
  // const [currentSlider, setCurrentSlider] = useState(0)

  //get the current slider position
  // useEffect(() => {
  //   setCurrentSlider(index % 30);
    // if (currentSlider === 0) {
    //   setCurrentSlider(30);
    // }
  // }, [index])
  
  return (
    <Wrapper>
      <div className="controls">
        <ButtonBack className="btn-arrow reverse-arrow" onClick={() => setIndex(index - 1)}>
          <img src={Arrow} alt="arrow" />
        </ButtonBack>
        {participantLength < 30 &&
          <DotGroup className="dot-group"/>
        }
        <ButtonNext className="btn-arrow" onClick={() => setIndex(index + 1)}>
          <img src={Arrow} alt="arrow" />
        </ButtonNext>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-arrow {
      border: none;
      background: none;
      padding: 11px 20px;
    }

    .reverse-arrow {
      transform: rotateY(180deg);
    }

    .dot-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 0 4px;
        padding: 0;
        background-color: #c3c4ca;
      }

      /* This class is found in DotGroup from pure-react-carousel */
      /* We need to override it to add our styles */
      .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: #6267a1;
        transition: background 0.4s ease;
      }
    }
  }
`;

export default CarouselSlider;