import React, { createContext, useState } from "react";


export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const prueba = 'hola'


  return (
    <EventContext.Provider
      value={{
        prueba
        
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => React.useContext(EventContext);
