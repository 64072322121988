import * as React from 'react';
import Papa from 'papaparse'
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { saveAs } from 'file-saver';

const LogsTable = ({ _header, _rows }) => {

  const exportTable = async () => {
    try {
      const csv = Papa.unparse(_rows);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `Logs-table.csv`)
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={exportTable} className='mx-5'>Exportar</Button>
      <TableContainer component={Paper} sx={{ minWidth: 650, maxWidth: '100%', marginTop: '2rem' }}>
        <Table sx={{ minWidth: 650, maxWidth: '100%', fontWeight: '600' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {_header.length > 0
                ? _header.map((col, index) => <TableCell key={index} sx={{ fontWeight: '700' }}>{col}</TableCell>)
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {_rows.map((row) => (
              <TableRow
                key={row.timestamp}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center" sx={{ fontWeight: '500' }}>
                  {new Date(row.timestamp).toLocaleString()}
                </TableCell>
                <TableCell align="left">{row.status === "Success" ? <Chip label="Success" sx={{ width: '80px', backgroundColor: '#CAE9C7', color: '#155A03', fontWeight: 'bold' }} /> : <Chip sx={{ width: '80px', backgroundColor: '#F1B2B2', color: '#750505', fontWeight: 'bold' }} label="Error" />}</TableCell>
                <TableCell align="left">{row.message}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default LogsTable