import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { cognitoConfig } from "./authConfig";
import { UserProvider } from "./context/userContext";
import { EventProvider } from './context/eventContext';
import 'bootstrap/dist/css/bootstrap.min.css'
/* Theme */
import { appTheme } from "./theme/theme";
import { ThemeProvider } from "@mui/material";


Amplify.configure(cognitoConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
      <Router>
      <ThemeProvider theme={appTheme}>
        <UserProvider>
          <EventProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </EventProvider>
        </UserProvider>
        </ThemeProvider>
      </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
