import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from '@mui/material/Chip';

export default function TransactionsTable({ header, rows }) {
  const validRows = Array.isArray(rows) ? rows : [];

  return (
    <TableContainer component={Paper} sx={{ minWidth: 650, maxWidth: 1200 }}>
    <Table sx={{ minWidth: 650, maxWidth: 1200, fontWeight: "600" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {header.length > 0
            ? header.map((col, index) => (
              <TableCell key={index} align={(index === 0 || col === "Minteado") ? "center" : "left"} sx={{ fontWeight: "700" }}>
                {col}
              </TableCell>
            ))
            : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {validRows.length > 0 ? (
          validRows.map((row, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" align="center" sx={{ fontWeight: "500" }}>
                {(row.idGalicia.toString()).padStart(4, "0")}
              </TableCell>
              <TableCell align="left">{new Date(row.mintDate).toLocaleString()}</TableCell>
              <TableCell align="left">{row.eventName}</TableCell>
              <TableCell align="center">{row.minted ? <Chip label="Minteado" sx={{ width: '105px', backgroundColor: '#CAE9C7', color: '#155A03', fontWeight: 'bold' }} /> : <Chip sx={{ width: '105px', backgroundColor: '#F1B2B2', color: '#750505', fontWeight: 'bold' }} label="No minteado" />}</TableCell>
              <TableCell align="left">{row.attributesTransaction.length > 0 ? `${(Number((row.attributesTransaction[0]).split('Matic cost')[1])).toFixed(10)} MATIC` : null}</TableCell>
              <TableCell align="left" sx={{ color: 'blue', textDecoration: 'underline', fontWeight: '500' }}>
                <a href={`https://mumbai.polygonscan.com/tx/${row.hashTransaction}`} target="_blank" rel="noreferrer">
                  {row.hashTransaction}
                </a>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={header.length} align="center" sx={{ fontWeight: "500" }}>
              No hay campos adecuados con los filtros de búsqueda
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
  );
}
