import React, { Suspense } from 'react'
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const GlbDisplay = ({ _file }) => {
    const ModelGlb = () => {
        const gltf = useLoader(GLTFLoader, _file);
        return (
            <>
                <group position={[0, -1, 0]}>
                    <primitive object={gltf.scene} />
                </group>
            </>
        );
    };

    return (
        <div style={{ width: '100%', height: '40vh' }}>
            <Canvas camera={{ position: [0, 0, 3], fov: 55 }} >
                <ambientLight intensity={0.6} />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={null}>
                    <ModelGlb />
                    <OrbitControls />
                </Suspense>
            </Canvas>
        </div>
    )
}

export default GlbDisplay