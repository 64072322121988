export const svgParser = (svg) => {
  if (!svg) return;
  let regex = /{{{[^{}]*}}}/g;
  let matches = svg.match(regex);
  let result = [];
  let client = [];
  let course = [];
  if (matches) {
    result = matches.map((match) => match.replace(/{{|}}/g, ""));
  }
  for (let i = 0; i < result.length; i++) {
    if (result[i].includes("{") || result[i].includes("}")) {
      course.push(result[i].slice(1, -1));
    } else client.push(result[i]);
  }
  return { course, client, matches };
};

export function svgReplace(data, fields, matches) {
  if (!matches) return;
  if (!fields.attributesEvent) return
  const obj = JSON.parse(replaceSpecial(JSON.stringify(fields.attributesEvent)));
  const regex = new RegExp(matches.join("|"), "gi");

  const newData = data.replace(regex, function (matched) {
    // const double = matched.slice(
    //   matched.indexOf("{") + 2,
    //   matched.lastIndexOf("?") - 1
    // );
    const triple = matched.slice(
      matched.indexOf("{") + 3,
      matched.lastIndexOf("}") - 2
    );

    // return obj[double] ? obj[double] : obj[triple] ? obj[triple] : "";
    return obj[triple] ? obj[triple] : "";
  });

  return newData;
};

function replaceSpecial(data) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  };
  const newData = data.replace(/[&<>]/g, function(m) { return map[m]; });
  return newData
};