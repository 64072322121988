import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Switch, TablePagination, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import BotonLista from '../../assets/icons/BotonLista';
import Papa from 'papaparse';
import { Typography} from "@mui/material";

export default function CRUDTable(props) {

    const [editMode, setEditMode] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [columns, setColumns] = useState(["mail", "actions"]);
    const [addMode, setAddMode] = useState(false);
    const [inputs, setInputs] = useState({});
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [previousRecord, setPreviousRecord] = useState({})
    const [selectedRows, setSelectedRows] = useState([])
    const setCertificates = props.setCertificates
    const [previousCertificate, setPreviousCertificate] = useState({})
    const [importState, setImportState] = useState(false)
    const [fileName, setFileName] = useState(null)

    const onRecordChange = (e) => {
        // Get the column name and value that was changed
        const column = e.target.getAttribute('id');
        const value = e.target.value;

        // Find the index of the certificate that was changed
        const index = props.certificates.findIndex(record => record.id === selectedId);

        // Create a new object for the updated certificate
        const updatedCertificate = { ...props.certificates[index], [column]: value };

        // Create a new array of certificates with the updated certificate
        const updatedCertificates = [...props.certificates];
        updatedCertificates[index] = updatedCertificate;
        setCertificates(updatedCertificates);
    };

    const onEdit = (id) => {
        setSelectedId(id);

        // find the index of the certificate with the given id in the certificates array
        const index = props.certificates.findIndex(record => record.id === id);

        // make a copy of the certificate at the found index
        const updatedRecord = { ...props.certificates[index] };

        // save the copy of the certificate in the previousRecord state
        setPreviousRecord(updatedRecord)

        // find the index of the certificate with the given id in the certificates array
        const indexx = props.certificates.findIndex(certificate => certificate.id === id);

        // make a copy of the certificate at the found index
        const updatedCertificate = { ...props.certificates[indexx] };

        // save the copy of the certificate in the previousCertificate state
        setPreviousCertificate(updatedCertificate)

        // set the edit mode to true
        setEditMode(true);
    };

    // This method is used to delete a certificate from the list and update the state
    const onDelete = (id) => {
        const updatedCertificates = props.certificates.filter((certificate) => certificate.id !== id);
        setCertificates(updatedCertificates);
  
    };

    const onSave = () => {
        setEditMode(false);
        setSelectedId(null);
    };

    const onAdd = () => {
        setAddMode(true);
    };

    const onConfirmAdd = () => {
        // Get the new record by combining the current inputs, certificate, and the current certificate count
        const newRecord = {
            id: props.certificates.length + 1,
            ...inputs,
            ...props.certificate
        };
        // Add the new record to the list of certificates
        setCertificates(prevCertificates => [...prevCertificates, newRecord])
        // Reset the input fields to their original value
        setInputs({});
        // Hide the add record window
        setAddMode(false);
    };

    const onCancelAdd = () => {
        setAddMode(false);
    };

    const onDiscard = () => {
        // 1. Make a copy of the certificates array
        const updatedCertificates = [...props.certificates];

        // 2. Find the index of the certificate that was changed
        const index = props.certificates.findIndex(record => record.id === selectedId);

        // 2. Update the copy with the new certificate
        updatedCertificates[index] = previousCertificate;

        // 3. Update the state with the new copy
        setCertificates(updatedCertificates);

        // 4. Switch to view mode
        setEditMode(false);

        // 5. Clear the selected ID
        setSelectedId(null);
    };

    const handleInputChange = (e) => {
        // Get the column name (id) of the input that triggered the change event
        const column = e.target.getAttribute('id');

        // Get the value of the input that triggered the change event
        const value = e.target.value;

        // Update the inputs object with the new value
        setInputs(prevInputs => ({ ...prevInputs, [column]: value }));
    };

    const handlePageChange = (event, page) => {
        setPage(page);
    }

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleRowSelection = (event, rowId) => {
        // Get the index of the row that the user clicked in the selectedRows array
        const selectedIndex = selectedRows.indexOf(rowId);
        // Create a new array to store the updated selected rows
        let newSelectedRows = [];
        // If the user clicked on a row that isn't already selected
        if (selectedIndex === -1) {
            // Add the new row to the selectedRows array
            newSelectedRows = newSelectedRows.concat(selectedRows, rowId);
        } else if (selectedIndex === 0) {
            // If the user clicked on the first row, remove it
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            // If the user clicked on the last row, remove it
            newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            // If the user clicked on a row in the middle of the array, remove it
            newSelectedRows = newSelectedRows.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }
        // Update the selectedRows array with the new array
        setSelectedRows(newSelectedRows);
    };

    const handleDeleteSelected = () => {
        // Remove the selected rows from the created records
        setCertificates(props.certificates.filter(record => !selectedRows.includes(record.id)));
        // Clear the selected rows array
        setSelectedRows([]);
    };

    const handleSwitch = (e) => {
        setImportState(e.target.checked)
    };

    const handleOnChange = (e) => {
        let fileName = e.target.files[0].name;
        setFileName(fileName);
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                
                let keys = Object.keys(results.data[0])
                setColumns([...keys, 'actions'])
                props.setCsvData(results.data)
                e.target.value = ''
            }
        });
    };

    const handleDeleteCSV = () => {
        // Delete the uploaded CSV file
        setFileName(null);
        
        setColumns([]);
      };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.certificates.length - page * rowsPerPage);

    return (
        <TableContainer sx={{ borderRadius: 3, paddingTop: 2, borderBottom: 0, paddingRight: 0, width: "100%" }}>

        
            <div className="lista-participantes-container">
                <h5 id="h5-lista-participantes">Importar lista de participantes</h5>
                <Switch sx={{ marginLeft: '50px' }} onChange={handleSwitch} checked={importState} size="big"   />
                <Button  component="label" disabled={!importState} className={importState ? 'btn-lista' : 'btn-lista-disabled'}>
                    <BotonLista className="boton-lista-svg" color={importState ? 'primary' : 'gray'} />
                    <Typography color={importState ? 'primary' : 'gray'} sx={{fontWeight: '700'}}>
                    Subir Lista
                    </Typography>
                    
                    
                    <input
                        hidden type={"file"}
                        id={"csvFileInput"}
                        accept={".csv, .xlsx"}
                        onChange={(e) => handleOnChange(e)}
                    />
                </Button>
                <br/>
            
                {fileName && <Button onClick={handleDeleteCSV}>{fileName}</Button>}
            </div>
            <Table aria-label="simple table" className='mt-3'>
                <StyledTableHead >
                    <StyledTableRow sx={{ height: 60, boxShadow: 1, borderRadius: 1, width: "100%" }}>
                        <StyledTableCell sx={{ width: 74, minWidth: 74 }} />
                        {
                            
                            columns.map((column, index) => {
                                return <StyledTableCell width={`${100 / columns.length}% !important`} key={index} align={'left'}>{column.charAt(0).toUpperCase() + column.slice(1)}</StyledTableCell>
                            })
                        }
                    </StyledTableRow>
                </StyledTableHead>
                <TableBody sx={{ paddingRight: 0 }}>
                    {props.certificates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                        <StyledTableRow height="100%" key={row.id} sx={editMode && row.id === selectedId ? { boxShadow: 4, borderRadius: 1, width: "100%" } : null}>
                            <StyledTableCell align="left" /* sx={{ width: 60, minWidth: 60 }} */>
                                <Checkbox type="checkbox" disabled={addMode || editMode} onChange={event => handleRowSelection(event, row.id)} />
                            </StyledTableCell>
                            {editMode && selectedId === row.id ? (
                                <>
                                    {
                                        columns.map((column, index) => {
                                            return (
                                                column === "actions" ? (
                                                    <StyledTableCell key={index} align={'left'} >
                                                        <IconButton aria-label="save" onClick={onSave}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                        <IconButton onClick={onDiscard}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </StyledTableCell>

                                                ) : (
                                                    <StyledTableCell width={`${100 / columns.length}% !important`} key={index} align={'left'}>
                                                        <TextField
                                                            id={column}
                                                            value={row[column]}
                                                            onChange={onRecordChange}
                                                            variant="standard"
                                                            inputProps={{
                                                                style: {
                                                                    textAlign: 'left',
                                                                    fontSize: 14,
                                                                    height: 30,
                                                                },
                                                            }}
                                                        />
                                                    </StyledTableCell>
                                                )
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <>
                                    {columns.map((column, index) => {
                                        return (
                                            <StyledTableCell key={index} width={`${100 / columns.length}% !important`} align={'left'}>
                                                {column === "actions" ? (
                                                    <>
                                                        <IconButton disabled={addMode} aria-label="edit" onClick={() => onEdit(row.id)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton disabled={addMode} aria-label="delete" onClick={() => onDelete(row.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    row[column]
                                                )}
                                            </StyledTableCell>
                                        );
                                    })}
                                </>
                            )}
                        </StyledTableRow>
                    ))}
                    {addMode && (
                        <StyledTableRow height="58.72px" /* sx={{ height: 74.71 }} */>
                            <StyledTableCell /* sx={{ width: 60, minWidth: 60 }} */ />
                            {
                                columns.map((column, index) => {
                                    return (
                                        <StyledTableCell key={index} /* width={`${100 / columns.length}%`} */ align={'left'}>
                                            {column === 'actions' ? (
                                                <>
                                                    <IconButton aria-label="confirm" onClick={onConfirmAdd}>
                                                        <CheckIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="cancel" onClick={onCancelAdd}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <TextField
                                                    onChange={handleInputChange}
                                                    id={column}
                                                    variant="standard"
                                                    inputProps={{ style: { fontSize: 14 } }}
                                                />
                                            )}
                                        </StyledTableCell>
                                    )
                                })
                            }
                        </StyledTableRow>
                    )}
                    {rowsPerPage === 5 ? emptyRows > 4 : emptyRows > 9 && (
                        <TableRow >
                            <StyledTableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={props.certificates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                className='table-pagination'
                
            />
        </TableContainer >
    );
}

// TableRow Component
const StyledTableRow = styled(TableRow)(({ theme, editMode, selectedRowId }) => ({
    // hover effect
    '&:hover': {
        backgroundColor: editMode ? 'lighBlue' : '#f5f5f5',
    },
    // last row
    /* '&:last-child td, &:last-child th': {
        border: 0,
    }, */
    '&:root': {
        minHeight: 30,
        /* flex: 1, */
    }
}));

// TableCell Component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    /* 
    overflow: "hidden",
    flex: 1, */
    /*     height: "100%",
    */
    padding: theme.spacing(1),
    height: 58.72,
    maxWidth: "auto",
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
    /* flex: 1, */
}));

