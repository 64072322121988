import * as React from "react";
import ModalCard from "../ModalCard";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';

const SuccessModal = ({
  _open,
  _handleClose,
  _title,
  _subtitle,
  _redirect = null,
}) => {
  let navigate = useNavigate();
  const routeChange = () => {
    if (_redirect === null) {
      _handleClose();
      return;
    }
    if (window.location.pathname === _redirect) {
      window.location.reload();
    } else {
      navigate(_redirect);
    }
  };

  return (
    <ModalCard
      className="success-modal-properties modal-properties"
      open={_open}
      handleClose={_handleClose}
    >
      <div className="modal-container">
        <VerifiedIcon sx={{ fontSize: '3rem' }} />
        <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "1.5rem" }}>
          {_title}
        </Typography>
        <Typography className={"modal-text"}>{_subtitle}</Typography>
      </div>

      <div className={"button-section justify-content-center mb-3"}>
        <Button variant="contained" color="primary" onClick={routeChange}>
          Continuar
        </Button>
      </div>
    </ModalCard>
  );
};

export default SuccessModal;
