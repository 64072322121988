/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import "./ReviewCertificate.css";
import CourseParticipantsTable from "../../components/Tables/CourseParticipantsTable";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/userContext";
import SlideShow from "../../components/SlideShow/SlideShow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactPlayer from "react-player";
import BounceLoader from "react-spinners/BounceLoader";
import GlbDisplay from "../../components/GlbDisplay/GlbDisplay";

const ReviewCertificate = () => {
  const tableHeader = [
    "ID",
    "Nombre",
    "Apellido",
    "Curso",
    "Mail",
    "Estado",
    "Fecha minteo",
  ];

  const { hash } = useParams();
  const { getHeader } = useUser();
  const [thisEvent, setThisEvent] = React.useState({});
  const [participants, setParticipants] = React.useState([]);
  const [templateUrl, setTemplateUrl] = React.useState(null);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const getData = async (_hash) => {
    try {
      const headers = await getHeader();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/event?hashEvent=${_hash}`,
        { headers }
      );
      console.log(response.data.body.participants)
      setThisEvent(response.data.body.event);
      setParticipants(response.data.body.participants);

      if (response.data.body.event.fileType !== "image/svg+xml") {
        const template = await getTemplate(
          response.data.body.event.idTemplate,
          headers
        );
        setTemplateUrl(template);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInitialLoading(false);
    }
  };

  const getTemplate = async (_id, _headers) => {
    try {
      const template = await axios.get(
        `${process.env.REACT_APP_API_BASE}/template?idTemplate=${_id}`,
        { headers: _headers }
      );
      console.log(template.request.response);
      console.log(JSON.parse(template.request.responseText));
      return template.data.body.uriTemplateS3;
    } catch (error) {
      console.error(error);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(hash);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${hash}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  React.useEffect(() => {
    getData(hash);
  }, [hash]);

  if (initialLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <BounceLoader color="#ff6600" />
      </div>
    );
  else
    return (
      <Container>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{ fontSize: "1.75rem", fontWeight: "bold", margin: "1rem 0rem" }}
        >
          {thisEvent.eventName}
        </Typography>
        <Row>
          <Col md={3}>
            <Typography
              variant="h6"
              component="h6"
              color="primary"
              sx={{ fontSize: "1rem", fontWeight: "bold", margin: "1rem 0rem" }}
            >
              Datos del evento
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className='data-event-name'
            >
              Nombre del evento:
              <Typography
                variant="label"
                component="label"
                className='data-event-desc'
              >
                {thisEvent.eventName}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className='data-event-name'
            >
              Tipo de evento:
              <Typography
                variant="label"
                component="label"
                className='data-event-desc'
              >
                {thisEvent.eventType}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className='data-event-name'
            >
              Evento nominado:
              <Typography
                variant="label"
                component="label"
                className='data-event-desc'
              >
                {thisEvent.isNominated ? "Si" : "No"}
              </Typography>
            </Typography>
            {thisEvent.attributesEvent.Descripción ? (
              <Typography
                variant="h6"
                component="h6"
                className='data-event-name'
              >
                Descripción:
                <Typography
                  variant="label"
                  component="label"
                  className='data-event-desc'
                >
                  {thisEvent.attributesEvent.Descripción}
                </Typography>
              </Typography>
            ) : null}
            
            {thisEvent.attributesEvent.Lugar ? (
              <Typography
                variant="h6"
                component="h6"
                className='data-event-name'
              >
                Lugar:
                <Typography
                  variant="label"
                  component="label"
                  className='data-event-desc'
                >
                  {thisEvent.attributesEvent.Lugar}
                </Typography>
              </Typography>
            ) : null}
            {thisEvent.attributesEvent.Fecha ? (
              <Typography
                variant="h6"
                component="h6"
                className='data-event-name'
              >
                Fecha:
                <Typography
                  variant="label"
                  component="label"
                  className='data-event-desc'
                >
                  {thisEvent.attributesEvent.Fecha}
                </Typography>
              </Typography>
            ) : null}
            {console.log(thisEvent)}
            {thisEvent.attributesEvent.properties.length > 0 ? (
              <Typography
                variant="h6"
                component="h6"
                className='data-event-name'
              >
                Propiedades opcionales:
                {thisEvent.attributesEvent?.properties.map((element, index) => (
                  <div key={index} className="d-flex flex-row">
                    <Typography
                      variant="label"
                      component="label"
                      sx={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      {console.log(element)}
                      {element.key}
                    </Typography>
                    <div style={{ padding: "0 1rem" }}>-</div>
                    <Typography
                      variant="label"
                      component="label"
                      sx={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      {element.value}
                    </Typography>
                  </div>
                ))}
              </Typography>
            ) : null}
          </Col>
          <Col md={5}>
            
            <div style={{ overflow: "hidden" }}>
              {thisEvent && thisEvent.fileType === "image/svg+xml" ? (
                <SlideShow _participants={participants} _hashEvent={hash} />
              ) : thisEvent.fileType === "video/mp4" ? (
                <ReactPlayer
                  url={templateUrl}
                  controls
                  style={{ maxHeight: "35rem", marginBottom: "1rem" }}
                />
              ) : thisEvent.fileType === "binary/octet-stream" ? (
                <GlbDisplay _file={templateUrl} />
              ) : (
                <img
                  src={templateUrl}
                  alt="Certificado"
                  className="review-img"
                  style={{ maxHeight: "35rem", marginBottom: "1rem" }}
                />
              )}
            </div>
          </Col>
          <Col md={4} className="mt-1 text-center">
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "1rem", fontWeight: "bold", margin: "1rem 1rem" }}
            >
              QR del curso
            </Typography>
            <QRCode value={hash} eyeRadius={10} id="qr-code" />

            <Typography variant="h5" component="h6">
              {hash?.slice(0, 8)}...{hash?.slice(59)}
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado"
              >
                <IconButton onClick={handleTooltipOpen}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <br />
            <Button className="descargar-btn" onClick={downloadQRCode}>
              Descargar
            </Button>
          </Col>
          <hr style={{marginTop: '1rem'}}/>
          {participants.length > 0 && (
            <Col md={12}>
              <Typography
                variant="h6"
                component="h2"
                color="primary"
                sx={{
                  fontSize: "1.75rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Lista de participantes
              </Typography>
              <CourseParticipantsTable
                _rows={participants}
                _header={tableHeader}
                _eventName={thisEvent.eventName}
              />
            </Col>
          )}
        </Row>
      </Container>
    );
};

export default ReviewCertificate;
