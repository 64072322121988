/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Select, MenuItem, Button } from '@mui/material'
import { useUser } from "../../context/userContext";
import LogsTable from '../Tables/LogsTable';

const limit = 10;

const LogsDropdown = ({ _allStreams }) => {

    const { getHeader } = useUser();
    const [stream, setStream] = React.useState(_allStreams[0].logName);
    const [logs, setLogs] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({});

    const getLogsByStream = async (_logName, _limit, _lastKey) => {
        try {
            const headers = await getHeader();
            const response = await axios.get(`${process.env.REACT_APP_API_BASE}/logs/streams?logName=${_logName}&count=${_limit}&lastKey=${_lastKey ? _lastKey : ""}`, { headers });
            setLogs(response.data.body.logs);
            setPaginationData(response.data.body.pagination)
        } catch (error) {
            console.error('error pa', error)
        }
    };

    React.useEffect(() => {
        getLogsByStream(stream, limit, "")
    }, [stream])

    // paginacion
    const handlePaginationChange = () => {
        getLogsByStream(stream, limit, paginationData.nextPageKey)
    };

    return (
        <>
            <Select
                value={stream}
                displayEmpty
                renderValue={(selected) => {

                    return selected;
                }}
                inputProps={{ "aria-label": "Without label" }}
                sx={{ width: "75%" }}
                onChange={(evt) => setStream(evt.target.value)}>
                {
                    _allStreams.map((log) =>
                        <MenuItem key={log.logName} value={log.logName}>{log.logName}</MenuItem>)
                }
            </Select>

            {logs.length > 0
                ? <>
                    <LogsTable _header={['Fecha y hora', 'Status', 'Mensaje']} _rows={logs} />
                    {paginationData.nextPageKey
                        ? <Row className='my-3'>
                            <Col className='mx-4 d-flex justify-content-end'>
                                <Button variant='contained' color='primary' onClick={handlePaginationChange}>Ver logs anteriores</Button>
                            </Col>
                        </Row>
                        : null
                    }
                </>
                : <div className="mt-4">No hay logs de este evento</div>}

        </>
    )
}

export default LogsDropdown