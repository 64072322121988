/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import axios from "axios";
import { useUser } from "../context/userContext";
import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import AllCoursesTable from "../components/Tables/AllCoursesTable";
import BounceLoader from "react-spinners/BounceLoader";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import AddIcon from "@mui/icons-material/Add";
import ModalCard from "../components/Modals/ModalCard";

const header = ["Curso", "Fecha", "Nominado", "Hash"];

const AllCourses = () => {
  const [data, setData] = React.useState([]);
  const { getHeader } = useUser();
  const [showHiddenRows, setShowHiddenRows] = React.useState(false);
  

  const getData = async () => {
    try {
      const headers = await getHeader();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/events`,
        { headers }
      );
      setData(response.data.body);
      console.log("data cursos", response.data.body);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleVisibilityChange = async (
    hashEvent,
    currentVisibility,
    creationDate
  ) => {
    
    try {
      const headers = await getHeader();
      const newVisibility = !currentVisibility;
      await axios.patch(
        `${process.env.REACT_APP_API_BASE}/event`,
        { hashEvent, creationDate, visibility: newVisibility },
        { headers }
      );
      setData(
        data.map((course) =>
          course.hashEvent === hashEvent
            ? { ...course, visibility: newVisibility }
            : course
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const filteredData = data.filter((course) => {
    if (showHiddenRows) return true;
    return course.visibility;
  });

  const exportTable = async () => {
    try {
      const formattedData = data.map((item) => {
        return {
          eventName: item.eventName,
          isNominated: item.isNominated,
          idTemplate: item.idTemplate,

          eventType: item.eventType,
          fileType: item.fileType,
          creationDate: new Date(item.creationDate).toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          hashEvent: item.hashEvent,
          attributesEvent: JSON.stringify(item.attributesEvent),
        };
      });

      const csv = Papa.unparse(formattedData, {
        columns: [
          "eventName",
          "isNominated",
          "idTemplate",

          "eventType",
          "fileType",
          "creationDate",
          "hashEvent",
          "attributesEvent",
        ],
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `courses.csv`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center my-3">
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{ fontSize: "1.75rem", fontWeight: "bold", marginBottom: "1rem" }}
        >
          Cursos
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={exportTable}
        >
          Exportar
        </Button>
      </div>

      {data.length > 0 ? (
        <AllCoursesTable
          rows={data}
          header={header}
          onVisibilityChange={handleVisibilityChange}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <BounceLoader color="#ff6600" />
        </div>
      )}
    </Container>
  );
};

export default AllCourses;
